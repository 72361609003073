@layer components {
  .iconButton {
    --anchor-display: inline-flex;
    --button-display: inline-flex;
    --button-placement: center;
    --anchor-placement: center;
    display: var(--icon-button-display);
    width: var(--icon-button-width);
    max-width: var(--icon-button-max-width);
    gap: var(--icon-button-gap);
    flex: var(--icon-button-flex);
    background-color: var(--icon-button-background-color, transparent);
    border-radius: var(--icon-button-border-radius, 0);
    height: var(--icon-button-height, auto);
    justify-content: var(--icon-button-justify-content, initial);
    align-items: var(--icon-button-align-items, initial);
  }

  .iconButton:hover {
    background-color: var(--icon-button-background-color-hover, transparent);
  }

  .iconButton:active {
    background-color: var(--icon-button-background-color-active, transparent);
  }

  .iconButtonReversed {
    --anchor-direction: row-reverse;
    --button-direction: row-reverse;
  }

  .iconButtonIcon {
    display: var(--icon-button-icon-display, flex);
  }
}
