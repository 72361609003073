.wrapper {
  margin: 0;
  border-top: 0;
  background: var(--section-background);
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--space-3x);
  text-align: left;
  font-size: var(--font-size-sm);

  @media (--screen-md) {
    flex-direction: row;
  }
}

.column {
  flex: 1 1 0;
}

.firstColumnParagraph {
  margin-bottom: var(--space-3x);
}

.firstColumnBoldParagraph {
  display: block;
  margin-bottom: var(--space-3x);
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

.divider {
  position: relative;
  flex: 1px 0 0;
  background: var(--color-gray-300);
  height: 100px;
}
